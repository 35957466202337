import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import { MainContext } from '@ezbobdev/ui-framework';
import { ScreenWrapper, styled } from '@ezbobdev/ui-widgets-ezbob';

import { wizardDirection } from '../../utils/constants';
import { useSteps } from '../../utils/useSteps';

const Wrapper = styled(ScreenWrapper)``;

const PageWrapper = (props) => {
  const {
    children,
    subtitleVariant,
    disclaimer,
    actions,
    customHeader,
    title,
    titleVariant,
    subtitle,
    backButton,
    maxWidth,
    fullWidth,
    contentFullWidth,
    className,
    isFinal,
    topImage,
    backgroundImage,
    variant,
    footer,
    showBackButtonArrowFallback,
    ...rest
  } = props;

  const { actions: ctxActions } = useContext(MainContext);
  const { shouldMoveToStep } = useSteps(props);

  const moveBack = () => {
    if (typeof backButton.onMoveBackClick === 'function') {
      return backButton.onMoveBackClick();
    }
    return shouldMoveToStep(wizardDirection.backward);
  };
  const { pageName } = ctxActions.userState.getCurrentPageDetails() ?? {};
  const currentStepName = ctxActions.userState.getCurrentStepName();
  const actionsVariant = ctxActions.settings.getUserConfigProp(`custom.${currentStepName}.stepActionsVariant`);

  const screenWrapperBackgroundImage = ctxActions.settings.getUserConfigProp(
    `custom.${currentStepName || pageName}.screenWrapper.backgroundImage`,
  );

  const showBackButtonArrow =
    ctxActions.settings.getUserConfigProp(`custom.${currentStepName || pageName}.screenWrapper.showBackButtonArrow`) ??
    showBackButtonArrowFallback;

  const hasBackButton = backButton && backButton.show;

  useEffect(() => {
    if (ctxActions.externalServices.datadog) {
      ctxActions.externalServices.datadog.startView(currentStepName);
    }
  }, [currentStepName, ctxActions]);

  return (
    <Wrapper
      className={className}
      title={title}
      titleVariant={titleVariant}
      subtitle={subtitle}
      subtitleVariant={subtitleVariant}
      customHeader={customHeader}
      onClickBack={hasBackButton ? moveBack : undefined}
      backButtonLabel={hasBackButton ? backButton.label : undefined}
      disclaimer={disclaimer}
      actions={actions}
      actionsVariant={actionsVariant}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      contentFullWidth={contentFullWidth}
      isFinalScreen={isFinal}
      topImage={topImage}
      backgroundImage={backgroundImage}
      variant={variant}
      screenWrapperBackgroundImage={screenWrapperBackgroundImage}
      footer={footer}
      showBackArrow={hasBackButton ? showBackButtonArrow : undefined}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

PageWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  customHeader: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backButton: PropTypes.object,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  isFinal: PropTypes.bool,
  contentFullWidth: PropTypes.bool,
};

export default PageWrapper;
